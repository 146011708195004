import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function LoadingButton(theme: Theme) {
  return {
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          '&.MuiButton-text': {
            '& .MuiLoadingButton-startIconPendingStart': {
              marginLeft: 0,
            },
            '& .MuiLoadingButton-endIconPendingEnd': {
              marginRight: 0,
            },
          },
          '&.MuiLoadingButton-loading.MuiButton-contained': {
            backgroundColor: theme.palette.button.dark,
            '.MuiLoadingButton-loadingIndicator': {
              color: theme.palette.button.pink,
            },
          },
        },
      },
    },
  };
}
