import React, { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

type IconProps = {
  color?: string;
  width?: number;
  height?: number;
} & BoxProps;

function CircleIcon({ color = '', width = 11, height = 11, ...other }: IconProps) {
  return (
    <Box {...other}>
      <svg width={width} height={height} viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5.17383" cy="5" r="4.98438" fill="inherit" />
      </svg>
    </Box>
  );
}

export default memo(CircleIcon);
