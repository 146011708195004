import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

type LogoProps = {
  width?: number;
  height?: number;
} & BoxProps;

function LogoBreinchild({ width = 30, height = 38, ...other }: LogoProps) {
  return (
    <Box {...other}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 30 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8">
          <path
            d="M30 23.9027C30 22.3851 28.7698 21.1534 27.252 21.1534H20.3109C23.7249 19.2019 26.0261 15.532 26.0261 11.3223C26.0261 5.069 20.9518 0 14.6921 0C8.43239 0 3.35816 5.069 3.35816 11.3223C3.35816 15.532 5.66069 19.2033 9.07331 21.1534H2.74657C1.22876 21.1534 0 22.3837 0 23.9027V23.9557C0 25.5026 1.25529 26.758 2.80521 26.758H9.71422L5.06027 32.9373C4.74471 33.3405 4.56039 33.8036 4.49476 34.2764C4.34117 35.1301 4.59251 36.0396 5.25157 36.6993C6.06702 37.5153 7.26646 37.705 8.26484 37.2782C8.72841 37.1025 9.1529 36.8053 9.47964 36.3869L14.6614 29.7612C14.6726 29.7472 14.6809 29.7319 14.6921 29.718C14.7033 29.7319 14.7117 29.7472 14.7228 29.7612L20.1615 36.7593L20.1699 36.7509C20.7982 37.3256 21.6263 37.5572 22.4152 37.4456C22.9178 37.3912 23.4121 37.2043 23.8408 36.8709C25.0612 35.9182 25.2776 34.1579 24.3253 32.9373L19.6686 26.758H27.1948C28.7433 26.758 30 25.504 30 23.9557C30 23.9473 30 23.9376 30 23.9292C30 23.9208 30 23.9111 30 23.9027V23.9027ZM22.4864 11.3502C22.4864 15.1805 19.7175 18.3608 16.0703 19.0108C15.7896 19.0611 15.5034 19.0945 15.2143 19.1141V3.58485C15.5048 3.60437 15.7896 3.63925 16.0703 3.68807C19.7175 4.33808 22.4864 7.51981 22.4864 11.3488V11.3502ZM6.90063 11.3502C6.90063 7.51981 9.66954 4.33948 13.3167 3.68946C13.5974 3.63925 13.8836 3.60577 14.1727 3.58624V19.1169C13.8822 19.0973 13.5974 19.0625 13.3167 19.0136C9.66954 18.3636 6.90063 15.1819 6.90063 11.3529V11.3502Z"
            fill="white"
          />
        </g>
      </svg>
    </Box>
  );
}

export default memo(LogoBreinchild);
