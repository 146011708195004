import { ReactNode, useRef } from 'react';
import { IconifyIcon } from '@iconify/react';
import { SnackbarProvider, SnackbarKey } from 'notistack';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, GlobalStyles, Collapse } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
// theme
import { ColorSchema } from '../theme/palette';
//
import Iconify from './Iconify';
import { IconButtonAnimate } from './animate';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();

  const isSidebar = document.getElementsByClassName('simplebar-content').length;

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContainer-root': {
            marginLeft: isSidebar ? '120px' : 0,
            transform: 'translateX(-50%) translateY(-50%)',
            bottom: '50%',
            top: '50%',
          },
          '& .SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(2, 8),
            margin: theme.spacing(0.25, 0),
            boxShadow:
              '0px 3.504px 10px -3.504px rgba(16, 24, 40, 0.25), 0px 10px 10.511px -1.752px rgba(16, 24, 40, 0.25)',
            borderRadius: '36px',
            border: '1px solid #CC6699',
            color: theme.palette.grey[800],
            backgroundColor: theme.palette.common.white,
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '& .SnackbarItem-message': {
            padding: '0 !important',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '24px',
            color: '#767D83',
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function NotistackProvider({ children }: Props) {
  const { themeDirection } = useSettings();

  const isRTL = themeDirection === 'rtl';

  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        iconVariant={{
          info: <SnackbarIcon icon={'eva:info-fill'} color="info" />,
          success: <SnackbarIcon icon={'eva:checkmark-circle-2-fill'} color="success" />,
          warning: <SnackbarIcon icon={'eva:alert-triangle-fill'} color="warning" />,
          error: <SnackbarIcon icon={'eva:alert-circle-fill'} color="error" />,
        }}
        // With close as default
        // action={(key) => (
        //   <IconButtonAnimate size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
        //     <Iconify icon={'eva:close-fill'} />
        //   </IconButtonAnimate>
        // )}
        hideIconVariant
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: IconifyIcon | string;
  color: ColorSchema;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Box>
  );
}
