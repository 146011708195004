import { createSlice } from '@reduxjs/toolkit';
// @types
import { TypeWorkBook, TypeBehaviourList, TypeBehaviourQuestion } from '_types/workBook';
import { UserManager } from '_types/user';
// store
import { dispatch } from '../store';
// mock data
import _workBookData from '_mock/work-book.json';
// lodash
import { isArray, cloneDeep } from 'lodash';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;

  // UPDATE ACCORDION
  activeAccordionBehaviour: string;

  // BEHAVIOUR
  dataBehaviour: TypeWorkBook[];
  activeBehaviourType: string;
  activeBehaviourDescriptor: string;
  activeBehaviourData: TypeBehaviourList;

  // UPDATE EXPLORE CONTENT
  activeExploreContentType: string;
  activeExploreContent: string;

  //UPDATE HOW ACT
  showHowAct: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,

  // UPDATE ACCORDION
  activeAccordionBehaviour: 'Review',

  // BEHAVIOUR
  dataBehaviour: _workBookData,
  activeBehaviourType: 'Identity',
  activeBehaviourDescriptor: 'People First',
  activeBehaviourData: _workBookData[0].behaviourList[0],
  // UPDATE EXPLORE CONTENT
  activeExploreContentType: '',
  activeExploreContent: '',
  //UPDATE HOW ACT
  showHowAct: false,
};

const slice = createSlice({
  name: 'workBook',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPDATE BEHAVIOUR
    updateActiveBehaviourType(state, action) {
      state.activeBehaviourType = action.payload;
    },
    updateActiveBehaviourDescriptor(state, action) {
      state.activeBehaviourDescriptor = action.payload;
    },
    updateActiveBehaviourData(state, action) {
      state.activeBehaviourData = action.payload;
    },

    // UPDATE ACTION
    updateAddMoreAction(state) {
      const actionNew = {
        title: '',
        description: '',
        showDate: false,
        startDate: undefined,
        endDate: undefined,
        showAssignMember: false,
        memberList: [],
      };
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );
      if (state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act) {
        const tempAct = state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act || [];
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act = tempAct.concat([
          actionNew,
        ]);
        state.activeBehaviourData.act = tempAct.concat([actionNew]);
      } else {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act = [actionNew];
        state.activeBehaviourData.act = [actionNew];
      }
    },
    updateRemoveAction(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );

      const tempAct = state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act || [];
      tempAct.splice(action.payload, 1);
      state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act = tempAct;
      state.activeBehaviourData.act = tempAct;
    },
    updateTitleAction(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );
      if (
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act &&
        isArray(state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act)
      ) {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
          action.payload.indexText
        ].title = action.payload.text;
        state.activeBehaviourData.act[action.payload.indexText].title = action.payload.text;
      }
    },
    updateDescriptionAction(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );
      if (
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act &&
        isArray(state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act)
      ) {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
          action.payload.indexText
        ].description = action.payload.text;
        state.activeBehaviourData.act[action.payload.indexText].description = action.payload.text;
      }
    },
    updateShowCalendarAction(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );
      if (
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act &&
        isArray(state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act)
      ) {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
          action.payload.indexText
        ].showDate = action.payload.show;
        state.activeBehaviourData.act[action.payload.indexText].showDate = action.payload.show;
      }
    },
    updateDateAction(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );
      if (
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act &&
        isArray(state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act)
      ) {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
          action.payload.indexText
        ].startDate = action.payload.from;
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
          action.payload.indexText
        ].endDate = action.payload.to;
        state.activeBehaviourData.act[action.payload.indexText].startDate = action.payload.from;
        state.activeBehaviourData.act[action.payload.indexText].endDate = action.payload.to;
      }
    },
    updateShowAssignMemberAction(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );
      if (
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act &&
        isArray(state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act)
      ) {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
          action.payload.indexText
        ].showAssignMember = action.payload.show;
        state.activeBehaviourData.act[action.payload.indexText].showAssignMember =
          action.payload.show;
      }
    },
    updateAssignMemberAction(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );
      if (
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act &&
        isArray(state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act)
      ) {
        const tempMemberAct =
          state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
            action.payload.indexText
          ].memberList || [];
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
          action.payload.indexText
        ].memberList = tempMemberAct.concat(action.payload.member);
        state.activeBehaviourData.act[action.payload.indexText].memberList = tempMemberAct.concat(
          action.payload.member
        );
      }
    },
    updateRemoveMemberAction(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );
      if (
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act &&
        isArray(state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act)
      ) {
        const tempMemberAct =
          state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
            action.payload.indexText
          ].memberList || [];
        tempMemberAct.splice(action.payload.indexMember, 1);
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].act[
          action.payload.indexText
        ].memberList = tempMemberAct;
        state.activeBehaviourData.act[action.payload.indexText].memberList = tempMemberAct;
      }
    },
    updateActiveAccordionBehaviour(state, action) {
      state.activeAccordionBehaviour = action.payload;
    },
    updateShowHowAct(state, action) {
      state.showHowAct = action.payload;
    },

    // REVIEW LOGIC
    updateReviewAction(state) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );

      const reviewTypeQuestions: Record<string, any> = {
        Identity: [
          {
            index: 1,
            description:
              'How would we describe our identity today in relation to this behaviour?  We are ...(self belief)',
          },
          { index: 2, description: 'What do we value? (Aspirations)' },
          {
            index: 3,
            description:
              'What image does our organisation have in relation to this behaviour?  How would others describe us? Consider customer and supplier perceptions. ',
          },
        ],
        Habits: [
          {
            index: 1,
            description:
              'What current Routines and Rituals support this Habit? What are the positive things we do?',
          },
          {
            index: 2,
            description:
              'What current Routines and Rituals  that are counter to this Habit? What are the things we do that might be barriers to this Behaviour? ',
          },
        ],
        Structures: [
          {
            index: 1,
            description:
              'What do we currently have in place that supports or enables this Structural Behaviour? ',
          },
          {
            index: 2,
            description:
              'Rate the performance of current Supports and Enablers...is it  serving this Structural Behaviour?',
          },
          {
            index: 3,
            description:
              'What do we currently have in place that undermines this Structural Behaviour?',
          },
        ],
      };

      if (
        !state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex]?.review ||
        (state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex]?.review &&
          state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex].review?.length === 0)
      ) {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].review =
          reviewTypeQuestions[state.dataBehaviour[typeIndex].behaviourType];
        state.activeBehaviourData.review =
          reviewTypeQuestions[state.dataBehaviour[typeIndex].behaviourType];
      } else {
        state.activeBehaviourData.review =
          state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].review;
      }
    },

    updateAnswerReview(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === action.payload.activeType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === action.payload.activeDescriptor
      );

      const indexTargetQuestion = state.dataBehaviour[typeIndex].behaviourList[
        descriptorIndex
      ].review.findIndex((i) => i.index === action.payload.index);

      if (indexTargetQuestion > -1) {
        // update value for review question
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].review[
          indexTargetQuestion
        ].answer = action.payload.answer;
      }
    },

    // EXPLORE LOGIC
    updateExploreQuestion(state) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );

      const exploreTypeQuestions: Record<string, any> = {
        Identity: [
          {
            index: 1,
            description: 'Who do we want to be?  We want to be...? We believe?',
          },
          { index: 2, description: 'What do we want to value? (Aspirations).' },
          {
            index: 3,
            description:
              'What image do we want to have in relation to this behaviour?  How do we want others to describe us?Consider customer and supplier perceptions.',
          },
        ],
        Habits: [
          {
            index: 1,
            description:
              'What Routines and Rituals might we develop to support this Habitual Behaviour?',
          },
        ],
        Structures: [
          {
            index: 1,
            description:
              'What Supports and Enablers might we develop to support this Structural Behaviour?',
          },
        ],
      };

      if (
        !state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex]?.explore ||
        (state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex]?.explore &&
          state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex].explore?.length === 0)
      ) {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].explore =
          exploreTypeQuestions[state.dataBehaviour[typeIndex].behaviourType];
        state.activeBehaviourData.explore =
          exploreTypeQuestions[state.dataBehaviour[typeIndex].behaviourType];
      } else {
        state.activeBehaviourData.explore =
          state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].explore;
      }
    },

    updateExploreAnswer(state, action) {
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === action.payload.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === action.payload.activeBehaviourDescriptor
      );

      const answer = action.payload.data;
      if (
        state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex]?.explore &&
        state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex]?.explore[answer.index - 1]
      ) {
        state.dataBehaviour[typeIndex].behaviourList[descriptorIndex].explore[answer.index - 1] =
          answer;
      }
    },

    updateActiveExploreContent(state, action) {
      state.activeExploreContentType = action.payload;
      const typeIndex = state.dataBehaviour.findIndex(
        (val) => val.behaviourType === state.activeBehaviourType
      );
      const descriptorIndex = state.dataBehaviour[typeIndex].behaviourList.findIndex(
        (val) => val.descriptor === state.activeBehaviourDescriptor
      );

      const content =
        state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex].content?.find(
          (content) => content.name === action.payload
        )?.description || '';

      if (state.dataBehaviour[typeIndex]?.behaviourList[descriptorIndex].content) {
        state.activeExploreContent = content;
      }

      if (!content) {
        state.activeExploreContentType = '';
      }
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function updateActiveBehaviourType(activeBehaviourType: string) {
  return () => {
    dispatch(slice.actions.updateActiveBehaviourType(activeBehaviourType));
  };
}

// ----------------------------------------------------------------------

export function updateActiveBehaviourDescriptor(activeBehaviourDescriptor: string) {
  return () => {
    dispatch(slice.actions.updateActiveBehaviourDescriptor(activeBehaviourDescriptor));
  };
}

// ----------------------------------------------------------------------

export function updateActiveBehaviourData(activeBehaviourData: TypeBehaviourList) {
  return () => {
    dispatch(slice.actions.updateActiveBehaviourData(activeBehaviourData));
  };
}

// ----------------------------------------------------------------------

export function updateAddMoreAction() {
  return () => {
    dispatch(slice.actions.updateAddMoreAction());
  };
}

// ----------------------------------------------------------------------

export function updateRemoveAction(index: number) {
  return () => {
    dispatch(slice.actions.updateRemoveAction(index));
  };
}

// ----------------------------------------------------------------------
export function updateTitleAction(textUpdate: { text: string; indexText: number }) {
  return () => {
    dispatch(slice.actions.updateTitleAction(textUpdate));
  };
}

// ----------------------------------------------------------------------

export function updateDescriptionAction(textUpdate: { text: string; indexText: number }) {
  return () => {
    dispatch(slice.actions.updateDescriptionAction(textUpdate));
  };
}

// ----------------------------------------------------------------------

export function updateShowCalendarAction(showCalendar: {
  show?: boolean | undefined;
  indexText: number;
}) {
  return () => {
    dispatch(slice.actions.updateShowCalendarAction(showCalendar));
  };
}

// ----------------------------------------------------------------------

export function updateDateAction(
  date: { from: Date | undefined; to?: Date | undefined } | undefined,
  indexText: number
) {
  return () => {
    if (date) {
      const newPrams = Object.assign(date, { indexText });
      dispatch(slice.actions.updateDateAction(newPrams));
    } else {
      const newPrams = Object.assign({ from: undefined, to: undefined }, { indexText });
      dispatch(slice.actions.updateDateAction(newPrams));
    }
  };
}

// ----------------------------------------------------------------------

export function updateShowAssignMemberAction(showAssignMember: {
  show?: boolean | undefined;
  indexText: number;
}) {
  return () => {
    dispatch(slice.actions.updateShowAssignMemberAction(showAssignMember));
  };
}

// ----------------------------------------------------------------------

export function updateAssignMemberAction(
  member: UserManager | undefined | null,
  indexText: number
) {
  return () => {
    if (member) {
      const cloneMember = cloneDeep(member);
      const newPrams = Object.assign({ member: cloneMember }, { indexText });
      dispatch(slice.actions.updateAssignMemberAction(newPrams));
    }
  };
}

// ----------------------------------------------------------------------

export function updateRemoveMemberAction(indexMember: number, indexText: number) {
  return () => {
    const newPrams = { indexMember, indexText };
    dispatch(slice.actions.updateRemoveMemberAction(newPrams));
  };
}

// ----------------------------------------------------------------------
export function updateActiveAccordionBehaviour(activeAccordionBehaviour: string) {
  return () => {
    dispatch(slice.actions.updateActiveAccordionBehaviour(activeAccordionBehaviour));
  };
}

export function updateShowHowAct(showHowAct: boolean) {
  return () => {
    dispatch(slice.actions.updateShowHowAct(showHowAct));
  };
}

// ----------------------------------------------------------------------
export function getReviewQuestions() {
  return () => {
    dispatch(slice.actions.updateReviewAction());
  };
}

// ----------------------------------------------------------------------
export function updateAnswerReview(dataUpdate: {
  activeType: string;
  activeDescriptor: string;
  answer: string;
  index: number;
}) {
  return () => {
    dispatch(slice.actions.updateAnswerReview(dataUpdate));
  };
}

// ----------------------------------------------------------------------
export function getExploreQuestions() {
  return () => {
    dispatch(slice.actions.updateExploreQuestion());
  };
}

// ----------------------------------------------------------------------

export function updateExploreAnswer(
  activeBehaviourType: string,
  activeBehaviourDescriptor: String,
  data: TypeBehaviourQuestion
) {
  return () => {
    dispatch(
      slice.actions.updateExploreAnswer({
        activeBehaviourType,
        activeBehaviourDescriptor,
        data,
      })
    );
  };
}

// ----------------------------------------------------------------------

export function updateExploreContentType(activeExploreContentType: string) {
  return () => {
    dispatch(slice.actions.updateActiveExploreContent(activeExploreContentType));
  };
}
