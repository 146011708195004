import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// routes
import { PATH_PAGE } from 'routes/paths';
// redux
import { clearLogout } from 'redux/slices/global';
import { useDispatch } from 'redux/store';
// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
  shouldLogoutFirst?: boolean;
};

export default function GuestGuard({ children, shouldLogoutFirst }: GuestGuardProps) {
  const { isAuthenticated, logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutCount, setLogoutCount] = useState(0);

  useEffect(() => {
    const forceLogout = () => {
      const username: string | null = localStorage.getItem('username');
      if (username) {
        try {
          logout(username).then(() => {
            dispatch(clearLogout());
            setLogoutCount(1);
          });
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (shouldLogoutFirst) {
      if (logoutCount === 0) {
        forceLogout();
      } else {
        if (isAuthenticated) {
          navigate(PATH_PAGE.scan);
        }
      }
    } else {
      if (isAuthenticated) {
        navigate(PATH_PAGE.scan);
      }
    }
  }, [isAuthenticated, logoutCount]);

  return <>{children}</>;
}
