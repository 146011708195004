import { industries } from './organisationProfile';

const onboarding = {
  id: 1,
  numberOfQuestions: 5,
  questions: [
    {
      displayOrder: 1,
      objStyle: {
        color: '#CC6699',
        questionTotal: 5,
      },
      progress: {
        title: 'Tell us a little about',
        element: '[$organisationName]',
        description:
          'Your answers will help us provide aggregated benchmark reporting so you can see how your organisation compares with others.<br /><br /> The specific information provided here is only available to your organisation and is otherwise confidential.<br /><br /> Refer to our Data Processing Policy for more information.',
        image: '',
        imageStyle: {},
      },
      question: [
        {
          systemId: 1,
          questionIndex: 1,
          questionType: '',
          sortingOrder: 1,
          element: '',
          theme: '',
          themeClass: '',
          title: '',
          titleClass: '',
          description: 'How many people are employed in your organisation?',
          answerType: 'boxes_single_selection',
          isDontKnowVisible: true,
          answer: [
            {
              text: '1-10',
              index: '0',
              visible: 'true',
            },
            {
              text: '11-50',
              index: '1',
              visible: 'false',
            },
            {
              text: '51-200',
              index: '2',
              visible: 'true',
            },
            {
              text: '201-500',
              index: '3',
              visible: 'false',
            },
            {
              text: '501-1,000',
              index: '4',
              visible: 'true',
            },
            {
              text: '1,001-5,000',
              index: '5',
              visible: 'true',
            },
            {
              text: '5,001-10,000',
              index: '6',
              visible: 'true',
            },
            {
              text: 'Over 10,000',
              index: '7',
              visible: 'true',
            },
          ],
        },
        {
          systemId: 2,
          questionIndex: 2,
          questionType: '',
          sortingOrder: 2,
          element: '',
          theme: '',
          themeClass: '',
          title: '',
          titleClass: '',
          description: 'What is your organisation type?',
          answerType: 'boxes_single_selection',
          isDontKnowVisible: true,
          answer: [
            {
              text: 'Public Company',
              index: '0',
              visible: 'true',
            },
            {
              text: 'Privately Held Company',
              index: '1',
              visible: 'false',
            },
            {
              text: 'Non Profit',
              index: '2',
              visible: 'true',
            },
            {
              text: 'Education Institution',
              index: '3',
              visible: 'false',
            },
            {
              text: 'Partnership',
              index: '4',
              visible: 'true',
            },
            {
              text: 'Government Agency',
              index: '5',
              visible: 'true',
            },
          ],
        },
        {
          systemId: 3,
          questionIndex: 3,
          questionType: '',
          sortingOrder: 3,
          element: '',
          theme: '',
          themeClass: '',
          title: '',
          titleClass: '',
          description: 'In which industries does your organisation operate?',
          answerType: 'dropdown_multi_selection',
          isDontKnowVisible: true,
          answer: industries.sort().map((industry, index) => ({
            text: industry.label,
            index: String(index),
            visible: true,
          })),
        },
        {
          systemId: 4,
          questionIndex: 4,
          questionType: '',
          sortingOrder: 4,
          element: '',
          theme: '',
          themeClass: '',
          title: '',
          titleClass: '',
          description: 'Where does your organisation primarily operate?',
          answerType: 'custom_location',
          isDontKnowVisible: true,
          answer: [],
        },
        {
          systemId: 5,
          questionIndex: 5,
          questionType: '',
          sortingOrder: 5,
          element: '',
          theme: '',
          themeClass: '',
          title: '',
          titleClass: '',
          description: 'How long has your organisation been in operation?',
          answerType: 'boxes_single_selection',
          isDontKnowVisible: true,
          answer: [
            {
              text: '0-2 years',
              index: '0',
              visible: 'true',
            },
            {
              text: '3-5 years',
              index: '1',
              visible: 'false',
            },
            {
              text: '6-10 years',
              index: '2',
              visible: 'true',
            },
            {
              text: '11-25 years',
              index: '3',
              visible: 'false',
            },
            {
              text: '26-50 years',
              index: '4',
              visible: 'true',
            },
            {
              text: '51-100 years',
              index: '5',
              visible: 'true',
            },
            {
              text: '100+ years',
              index: '6',
              visible: 'true',
            },
          ],
        },
      ],
    },
    {
      displayOrder: 2,
      objStyle: {
        color: '#CC6699',
        questionTotal: 1,
      },
      progress: {
        title: '',
        element: 'Thank You!',
        description: 'You’ve ticked the first box',
        image: 'ticked.svg',
        imageStyle: {
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        },
      },
      question: [
        {
          systemId: 1,
          questionIndex: 1,
          questionType: 'Profiling',
          sortingOrder: 1,
          identityUnderstanding: '',
          element: '',
          theme: '',
          themeClass: '',
          title: '',
          titleClass: '',
          description:
            'Your organisation’s profile is complete.<br /><br /> Thanks for that!<br /><br /> Now is a great time to visit the Hub, complete your Scan, and invite the rest of your team on board.',
          answerType: 'intro',
          isDontKnowVisible: false,
          answer: null,
        },
      ],
    },
  ],
};

export default onboarding;
