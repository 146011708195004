import { styled } from '@mui/material';
import { memo } from 'react';

// ----------------------------------------------------------------------

const NotificationStyle = styled('span')(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '999px',
  backgroundColor: color,
  color: 'white',
  font: `400 10px/14px 'Inter'`,
  height: '18px',
  minWidth: '18px',
  paddingRight: '1px',
}));

type IconProps = {
  color?: string;
  number: number;
};

function ColorBubble({ color = '#7BCB7B', number }: IconProps) {
  return number ? <NotificationStyle color={color}>{number}</NotificationStyle> : <></>;
}

export default memo(ColorBubble);
