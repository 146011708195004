import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_PAGE } from '../routes/paths';
// redux
import { useSelector } from 'redux/store';
// ----------------------------------------------------------------------

type IntroGuardProps = {
  children: ReactNode;
};

export default function IntroGuard({ children }: IntroGuardProps) {
  const { scanComplete } = useSelector((state) => state.questionnaire);
  const { state, pathname } = useLocation();
  const [isUpgrade, setIsUpgrade] = useState((state as any)?.isUpgrade || false);
  if (localStorage.getItem('shouldSelectPlan') && !isUpgrade) {
    return <Navigate to={PATH_PAGE.selectPlan} />;
  }

  if (scanComplete) {
    if (pathname === PATH_PAGE.scan) {
      return <Navigate to={PATH_PAGE.hub} />;
    }
  }

  return <>{children}</>;
}
