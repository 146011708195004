// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export default breakpoints;

// ----------------------------------------------------------------------

type ViewPortType = 'width' | 'height';
type KeyType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;
const unit = 'px';

export class CreateCustomBreakpoints {
  static up(viewport: ViewPortType, key: KeyType) {
    const value = typeof key === 'number' ? key : breakpoints.values[key];
    return `@media (min-${viewport}:${value}${unit})`;
  }

  static down(viewport: ViewPortType, key: KeyType) {
    const value = typeof key === 'number' ? key : breakpoints.values[key];
    return `@media (max-${viewport}:${value - 5 / 100}${unit})`;
  }
}
