import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

function LogoEmplify({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg width="218" height="62" viewBox="0 0 218 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2930_1527)">
          <path d="M42.2281 33.949C41.0121 33.949 37.9179 33.7538 35.4296 31.9673C34.4645 31.2735 34.1356 29.9509 34.6766 28.9579C35.0834 28.2164 35.8537 27.7524 36.6932 27.7524C37.1866 27.7524 37.6583 27.9085 38.0521 28.2034C39.5667 29.3265 41.9512 29.3569 42.2195 29.3569C42.3147 29.3569 42.5873 29.3482 42.5873 29.3482C47.1269 29.3482 50.814 25.6537 50.814 21.1136C50.814 17.5058 47.2914 13.5208 41.605 13.3777C41.3756 13.3734 41.1506 13.369 40.9299 13.369C37.2731 13.369 34.2222 14.1365 30.4053 16.0228C26.9692 17.7183 23.2605 21.6773 22.4643 24.4959C22.4253 24.6433 22.3864 24.7821 22.3561 24.9165C22.1094 25.9442 21.2309 26.6684 20.2139 26.69H20.0928C19.3917 26.69 18.7426 26.3778 18.3055 25.8271C17.8814 25.2894 17.7256 24.5956 17.8814 23.9278C17.929 23.7197 17.9852 23.5029 18.0502 23.2731C19.396 18.4902 24.2645 13.9327 28.38 11.9034C32.8201 9.71355 36.5764 8.78125 40.9515 8.78125C41.2068 8.78125 41.4622 8.78125 41.7175 8.78992C45.1016 8.87665 48.4901 10.1212 51.0131 12.2026C53.8346 14.5355 55.3925 17.701 55.3925 21.1136C55.3925 28.1557 49.6758 33.9099 42.6479 33.936C42.5657 33.936 42.4186 33.9446 42.2195 33.9446L42.2281 33.949Z" fill="#F7A273" />
          <path d="M42.3017 42.5955C32.1968 42.5305 27.9126 35.8353 27.4582 35.0721C27.0687 34.4564 25.1603 31.1695 26.0387 27.3145C26.6619 24.5783 28.4838 22.2974 31.4525 20.5369C33.2571 19.4659 35.3083 18.9238 37.55 18.9238C40.7221 18.9238 43.1672 20.0079 43.4398 20.1293C43.9937 20.3852 44.4135 20.8405 44.6212 21.4085C44.8289 21.9766 44.8073 22.601 44.552 23.1517C44.1798 23.9582 43.3706 24.4786 42.4834 24.4786C42.1545 24.4786 41.8343 24.4092 41.5314 24.2705C41.514 24.2618 39.7484 23.4856 37.5587 23.4856C36.1349 23.4856 34.8583 23.8151 33.7721 24.4612C31.9329 25.554 30.825 26.8462 30.4831 28.2988C29.9682 30.4887 31.2491 32.5441 31.3054 32.6308L31.3746 32.7435C31.5044 32.9603 34.551 37.9861 42.2627 38.0381H42.3796C50.2557 38.0381 54.5183 32.956 54.6957 32.7392C55.1285 32.2102 55.7733 31.9066 56.4571 31.9066C56.9764 31.9066 57.487 32.0887 57.8895 32.4183C58.8588 33.2075 59.006 34.6428 58.2227 35.6141C57.9933 35.9003 52.4714 42.5999 42.3709 42.5999H42.2973L42.3017 42.5955Z" fill="#B76BAB" />
          <path d="M59.8022 31.3642C59.4863 31.3642 59.179 31.2992 58.8891 31.1691C58.3308 30.9219 57.9067 30.4753 57.686 29.9073C57.4653 29.3392 57.4783 28.7191 57.7249 28.1641C58.664 26.0263 59.1401 23.7411 59.1401 21.3822C59.1401 16.8898 57.3614 12.6576 54.1461 9.46174C50.961 6.3006 46.7806 4.55742 42.3665 4.55742H42.2064C40.0556 4.57477 37.9611 4.99538 35.979 5.8106C35.7021 5.92335 35.4121 5.97972 35.1179 5.97972C34.1918 5.97972 33.3652 5.42467 33.0103 4.56176C32.5343 3.39964 33.0926 2.06406 34.2524 1.58708C36.7667 0.559379 39.4281 0.0216814 42.1631 0H42.3275C47.9663 0 53.3022 2.2115 57.3398 6.22688C61.425 10.2856 63.6753 15.6669 63.6796 21.3822C63.6796 24.3785 63.0781 27.2795 61.8837 30.007C61.5202 30.8352 60.7023 31.3686 59.7978 31.3686L59.8022 31.3642Z" fill="#F7B3B4" />
          <path d="M37.446 56.367C36.8186 56.367 36.2387 56.1198 35.8059 55.6645L23.7667 43.0937C22.8968 42.1874 22.9271 40.7391 23.8316 39.8718C24.2557 39.4599 24.8183 39.2344 25.4068 39.2344C26.0343 39.2344 26.6142 39.4815 27.047 39.9368L39.0862 52.5077C39.956 53.414 39.9257 54.8623 39.0213 55.7295C38.5972 56.1415 38.0346 56.367 37.446 56.367Z" fill="#F7A273" />
          <path d="M42.7086 50.5567C42.0898 50.5567 41.5099 50.3096 41.0771 49.8673L40.2549 49.0217C39.8308 48.5837 39.6014 48.0114 39.6101 47.3999C39.6188 46.7929 39.8611 46.2205 40.2982 45.7955C40.7266 45.3792 41.2892 45.1494 41.8864 45.1494C42.4836 45.1494 43.0851 45.3966 43.5179 45.8389L44.3401 46.6845C44.7642 47.1224 44.9936 47.6948 44.9849 48.3062C44.9763 48.9133 44.7339 49.4857 44.2968 49.9106C43.8684 50.3269 43.3058 50.5567 42.7086 50.5567Z" fill="#B76BAB" />
          <path d="M31.9113 62.0002C31.2838 62.0002 30.6995 61.7487 30.2668 61.2977L9.83645 39.8852C9.41668 39.4429 9.19165 38.8662 9.20463 38.2591C9.21762 37.652 9.46861 37.084 9.9057 36.6634C10.3298 36.2558 10.888 36.0303 11.4766 36.0303C12.1041 36.0303 12.6883 36.2818 13.1211 36.7371L33.5514 58.1496C33.9712 58.5919 34.1962 59.1686 34.1832 59.7757C34.1702 60.3828 33.9192 60.9508 33.4822 61.3714C33.0581 61.779 32.4998 62.0045 31.9113 62.0045V62.0002Z" fill="#B76BAB" />
          <path d="M21.3133 39.6158C20.6858 39.6158 20.106 39.3687 19.6732 38.9134L13.1343 32.0881C8.92358 27.8559 8.87598 22.6697 8.87598 22.4485C8.87598 16.1132 13.1256 10.4848 19.6992 8.10415C19.9502 8.01309 20.2098 7.96973 20.4738 7.96973C21.4302 7.96973 22.287 8.57681 22.6116 9.47441C22.8193 10.0468 22.789 10.6669 22.5294 11.2176C22.2697 11.7683 21.8153 12.1846 21.2441 12.3927C16.4189 14.1402 13.4242 17.9908 13.4242 22.4485C13.4242 22.5873 13.5108 26.0173 16.3713 28.8836L22.9578 35.7566C23.3776 36.1945 23.6026 36.7756 23.5896 37.3827C23.5766 37.9897 23.33 38.5578 22.8886 38.9827C22.4645 39.3903 21.9019 39.6158 21.3133 39.6158Z" fill="#B76BAB" />
          <path d="M7.33501 36.3509C6.70752 36.3509 6.12763 36.1037 5.69487 35.6484L4.69521 34.5947C4.44421 34.2868 4.21918 33.9963 3.99847 33.6971C3.63063 33.1984 3.49215 32.587 3.59601 31.9756C3.7042 31.3598 4.06339 30.8091 4.57836 30.4666C4.95486 30.2151 5.39194 30.085 5.84201 30.085C6.56038 30.085 7.2398 30.4275 7.6639 31.0043C7.81969 31.2167 7.98414 31.4292 8.15292 31.6417L8.92755 32.4482C9.76709 33.3242 9.81469 34.6988 9.03573 35.579C8.60298 36.069 7.97981 36.3509 7.33068 36.3509H7.33501Z" fill="#F7A273" />
          <path d="M2.99057 28.9359C1.97793 28.9359 1.07347 28.2508 0.787851 27.2708C-0.0257269 24.4609 -0.207484 21.4385 0.238253 18.2817C0.87873 13.7503 2.92566 9.62219 6.14968 6.34396C9.37803 3.06574 13.4719 0.962652 17.9812 0.264513C19.0934 0.0910617 20.2272 0 21.3437 0C24.0787 0 26.7358 0.503007 29.2371 1.50035C29.804 1.72584 30.2454 2.15513 30.4878 2.71451C30.7301 3.27388 30.7388 3.89397 30.5137 4.45769C30.1675 5.33361 29.3367 5.89733 28.3976 5.89733C28.112 5.89733 27.8263 5.84529 27.558 5.73689C25.5933 4.95636 23.5031 4.55742 21.348 4.55742C20.4609 4.55742 19.5607 4.6268 18.6779 4.76556C11.4942 5.87998 5.76453 11.6993 4.74323 18.9192C4.39269 21.4082 4.5355 23.7888 5.18031 25.9959C5.50055 27.093 4.95528 28.2725 3.92099 28.7365C3.62672 28.8666 3.31514 28.9359 2.9949 28.9359H2.99057Z" fill="#F4CB72" />
          <path d="M84.4907 44.4945V18.7023C84.4907 17.267 85.6548 16.1006 87.0872 16.1006H101.879C102.987 16.1006 103.887 17.0025 103.887 18.1126C103.887 19.2227 102.987 20.1246 101.879 20.1246H89.1601V29.569H100.875C101.978 29.569 102.874 30.4667 102.874 31.5724C102.874 32.6781 101.978 33.5758 100.875 33.5758H89.1601V43.0679H102.061C103.168 43.0679 104.069 43.9698 104.069 45.0799C104.069 46.19 103.168 47.0919 102.061 47.0919H87.0916C85.6592 47.0919 84.495 45.9255 84.495 44.4902L84.4907 44.4945Z" fill="#686872" />
          <path d="M107.094 47.0966V23.8499H111.43V27.6354H111.715C112.2 26.3519 112.988 25.3546 114.087 24.6304C115.186 23.9106 116.497 23.5463 118.029 23.5463C119.561 23.5463 120.881 23.9106 121.933 24.6347C122.984 25.3632 123.763 26.3606 124.265 27.6311H124.508C125.062 26.3909 125.944 25.3979 127.152 24.6564C128.359 23.9149 129.8 23.542 131.471 23.542C133.574 23.542 135.296 24.2011 136.629 25.5237C137.962 26.8462 138.628 28.8366 138.628 31.5034V47.0923H134.11V31.9283C134.11 30.3543 133.682 29.2138 132.825 28.507C131.968 27.8002 130.947 27.449 129.757 27.449C128.286 27.449 127.143 27.8999 126.33 28.8019C125.512 29.7038 125.105 30.8616 125.105 32.2752V47.0923H120.604V31.6378C120.604 30.376 120.21 29.3613 119.427 28.5937C118.644 27.8262 117.618 27.4446 116.359 27.4446C115.502 27.4446 114.714 27.6701 113.996 28.1168C113.278 28.5677 112.698 29.1878 112.261 29.9857C111.824 30.7836 111.603 31.7072 111.603 32.7566V47.0923H107.085L107.094 47.0966Z" fill="#686872" />
          <path d="M141.926 53.5529V23.8494H146.336V27.6177H146.713C146.977 27.132 147.353 26.5726 147.846 25.9395C148.34 25.3021 149.023 24.747 149.902 24.2657C150.776 23.7887 151.936 23.5459 153.377 23.5459C155.251 23.5459 156.921 24.0186 158.393 24.9682C159.864 25.9178 161.019 27.2838 161.859 29.0703C162.698 30.8569 163.123 33.0076 163.123 35.5183C163.123 38.029 162.703 40.1842 161.868 41.9751C161.032 43.7659 159.886 45.1449 158.423 46.1075C156.964 47.0702 155.294 47.5515 153.425 47.5515C152.014 47.5515 150.863 47.313 149.971 46.8403C149.08 46.3677 148.383 45.8127 147.881 45.1752C147.379 44.5378 146.989 43.9741 146.717 43.4797H146.444V53.5529C146.444 54.8017 145.432 55.8164 144.185 55.8164C142.939 55.8164 141.926 54.8017 141.926 53.5529ZM146.353 35.475C146.353 37.1098 146.591 38.5407 147.063 39.7722C147.535 41.0037 148.223 41.9664 149.119 42.6558C150.014 43.3453 151.114 43.6922 152.412 43.6922C153.71 43.6922 154.887 43.3323 155.796 42.6081C156.701 41.8883 157.389 40.904 157.856 39.6638C158.323 38.4236 158.557 37.023 158.557 35.4706C158.557 33.9183 158.328 32.561 157.869 31.3382C157.41 30.1153 156.726 29.1527 155.813 28.4459C154.9 27.7391 153.766 27.3878 152.408 27.3878C151.049 27.3878 149.993 27.7261 149.093 28.4025C148.193 29.079 147.509 30.02 147.046 31.2341C146.583 32.4483 146.349 33.8576 146.349 35.4706L146.353 35.475Z" fill="#686872" />
          <path d="M170.86 17.1454V47.1003H166.342V17.1454C166.342 15.8965 167.355 14.8818 168.601 14.8818C169.848 14.8818 170.86 15.8965 170.86 17.1454Z" fill="#686872" />
          <path d="M178.044 20.2633C177.26 20.2633 176.585 19.9988 176.027 19.4698C175.469 18.9408 175.188 18.3077 175.188 17.5705C175.188 16.8334 175.469 16.1872 176.027 15.6539C176.585 15.1249 177.26 14.8604 178.044 14.8604C178.827 14.8604 179.502 15.1249 180.06 15.6539C180.619 16.1829 180.9 16.8203 180.9 17.5705C180.9 18.3207 180.619 18.9408 180.06 19.4698C179.502 19.9988 178.827 20.2633 178.044 20.2633ZM175.763 47.0962V26.1086C175.763 24.8598 176.776 23.8451 178.022 23.8451C179.268 23.8451 180.281 24.8598 180.281 26.1086V47.0962H175.763Z" fill="#686872" />
          <path d="M194.515 25.6664C194.515 26.668 193.701 27.4833 192.701 27.4833H183.869C182.869 27.4833 182.056 26.668 182.056 25.6664C182.056 24.6647 182.869 23.8495 183.869 23.8495H192.701C193.701 23.8495 194.515 24.6647 194.515 25.6664ZM184.998 47.0962V21.1523C184.998 19.6996 185.314 18.4898 185.95 17.5272C186.587 16.5645 187.426 15.8404 188.473 15.3634C189.521 14.882 190.659 14.6436 191.888 14.6436C192.792 14.6436 193.571 14.7173 194.216 14.8647C194.22 14.8647 194.229 14.8647 194.233 14.869C195.017 15.0468 195.48 15.8534 195.255 16.6252L194.588 18.9278C194.376 18.8671 194.104 18.7934 193.775 18.7066C193.441 18.6199 193.039 18.5766 192.567 18.5766C191.468 18.5766 190.685 18.8497 190.217 19.3961C189.75 19.9425 189.516 20.7273 189.516 21.755V47.0919H184.998V47.0962Z" fill="#686872" />
          <path d="M200.975 55.7553C200.3 55.7553 199.686 55.7033 199.132 55.5949C198.578 55.4908 198.167 55.3738 197.894 55.2523L198.98 51.5448C200.218 51.8874 201.257 51.9177 202.1 51.6272C202.94 51.341 203.65 50.4434 204.221 48.9387L204.779 47.395L196.289 23.8447H201.122L206.999 41.8879H207.242L213.118 23.8447L217.996 23.8924L208.401 50.1962C207.96 51.3974 207.393 52.4121 206.709 53.2403C206.026 54.0685 205.208 54.693 204.264 55.1179C203.317 55.5429 202.222 55.7553 200.971 55.7553H200.975Z" fill="#686872" />
        </g>
        <defs>
          <clipPath id="clip0_2930_1527">
            <rect width="218" height="62" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}

export default memo(LogoEmplify);
