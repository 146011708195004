import * as api from 'utils/axios';
import * as endpoints from 'services/scan/endpoints';
import { SCAN_API } from 'config';

export const scanList = () =>
  api.sendGet(SCAN_API + endpoints.SCAN_LIST, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const engagementStats = () =>
  api.sendGet(SCAN_API + endpoints.SCAN_STATS_ENGAGEMENT, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
