export { default as industries } from './industries.json';

export const organisationTypes = [
  'Public Company',
  'Privately Held Company',
  'Non Profit',
  'Education Institution',
  'Partnership',
  'Government Agency',
];

export const employeeNumbers = [
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1,000',
  '1,001-5,000',
  '5,001-10,000',
  'Over 10,000',
];

export const operationIntervals = [
  '0-2 years',
  '3-5 years',
  '6-10 years',
  '11-25 years',
  '26-50 years',
  '51-100 years',
  '100+ years',
];
