import { Link as RouterLink, Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import { LogoBreinchild } from 'assets';
// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  padding: `${22}px ${22}px`,
  zIndex: 9,
  lineHeight: '160%',
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'flex-start',
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <LogoBreinchild />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
