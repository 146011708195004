// utils
import createAvatar from 'utils/createAvatar';
// components
import Avatar, { Props as AvatarProps } from 'components/Avatar';
// redux
import { useSelector } from 'redux/store';
// ----------------------------------------------------------------------

export interface Props extends AvatarProps {
  isThumbnail?: boolean;
}

export default function UserAvatar({ isThumbnail = false, ...other }: Props) {
  const { profileInfo } = useSelector((state) => state.profile);
  const { thumbnailAvatarUrl, mainAvatarUrl, firstName, lastName } = profileInfo;
  const avatarUrl = isThumbnail ? thumbnailAvatarUrl : mainAvatarUrl;
  const displayName = `${firstName?.split(' ')[0]} ${lastName?.split(' ')[0]}`;

  return (
    <Avatar
      src={avatarUrl}
      alt={`${firstName} ${lastName}`}
      color={avatarUrl ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {(displayName && createAvatar(displayName).name) || null}
    </Avatar>
  );
}
