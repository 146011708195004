import { createSlice } from '@reduxjs/toolkit';
// @types
import { UserManager } from '_types/user';
// store
import { dispatch } from '../store';
// services
import * as services from 'services/user';
// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;

  //USER LIST
  userList: UserManager[] | [];
  isLoadingUserList: boolean;
  errorUserList: Error | string | null;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,

  userList: [],
  isLoadingUserList: false,
  errorUserList: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingUserList(state) {
      state.isLoadingUserList = true;
      state.errorUserList = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasErrorUserList(state, action) {
      state.isLoadingUserList = false;
      state.errorUserList = action.payload;
      state.userList = [];
    },
    // UPDATE USER LIST
    updateUserList(state, action) {
      state.isLoadingUserList = false;
      state.userList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getUserList() {
  return () => {
    dispatch(slice.actions.startLoadingUserList());
    try {
      services
        .userList()
        .then((response: any) => {
          dispatch(slice.actions.updateUserList(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorUserList(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorUserList(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateUserList(userList: UserManager[]) {
  return () => {
    dispatch(slice.actions.updateUserList(userList));
  };
}
// ----------------------------------------------------------------------
