// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  errorAfterSubmit?: boolean;
  helperTextAfterSubmit?: string;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({
  name,
  errorAfterSubmit,
  helperTextAfterSubmit,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          // error={!!error || !!errorAfterSubmit}
          helperText={helperTextAfterSubmit}
          {...other}
        />
      )}
    />
  );
}
