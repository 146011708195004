import { createSlice } from '@reduxjs/toolkit';
// @types
import { ScanManagement, TypeEngagementStats } from '_types/scan';
// store
import { dispatch } from '../store';
// services
import * as services from 'services/scan';
import * as summaryServices from 'services/summary';
import { TypeSummary } from '../../_types/summary';
// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;

  //SCAN LIST
  scanList: ScanManagement[] | [];
  isLoadingScanList: boolean;
  errorScanList: Error | string | null;

  // ENGAGEMENT STATS
  engagementStats: TypeEngagementStats | null;
  isLoadingEngagementStats: boolean;
  errorEngagementStats: Error | string | null;

  // SUMMARY
  summary: TypeSummary | null;
  isLoadingSummary: boolean;
  errorSummary: Error | string | null;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,

  scanList: [],
  isLoadingScanList: false,
  errorScanList: null,

  engagementStats: null,
  isLoadingEngagementStats: false,
  errorEngagementStats: null,

  summary: null,
  isLoadingSummary: false,
  errorSummary: null,
};

const slice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SCAN LIST
    startLoadingScanList(state) {
      state.isLoadingScanList = true;
      state.errorScanList = null;
    },
    hasErrorScanList(state, action) {
      state.isLoadingScanList = false;
      state.errorScanList = action.payload;
      state.scanList = [];
    },
    updateScanList(state, action) {
      state.isLoadingScanList = false;
      state.scanList = action.payload;
    },
    //  ENGAGEMENT STATS
    startLoadingEngagementStats(state) {
      state.isLoadingEngagementStats = true;
      state.errorEngagementStats = null;
      state.engagementStats = null;
    },
    hasErrorEngagementStats(state, action) {
      state.isLoadingEngagementStats = false;
      state.errorEngagementStats = action.payload;
    },
    updateEngagementStats(state, action) {
      state.isLoadingEngagementStats = false;
      state.engagementStats = action.payload;
    },
    //  SUMMARY
    startLoadingSummary(state) {
      state.isLoadingSummary = true;
      state.errorSummary = null;
    },
    hasErrorSummary(state, action) {
      state.isLoadingSummary = false;
      state.errorSummary = action.payload;
    },
    updateSummary(state, action) {
      state.isLoadingSummary = false;
      state.summary = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getScanList() {
  return () => {
    dispatch(slice.actions.startLoadingScanList());
    try {
      services
        .scanList()
        .then((response: any) => {
          dispatch(slice.actions.updateScanList(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorScanList(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorScanList(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEngagementStats() {
  return () => {
    dispatch(slice.actions.startLoadingEngagementStats());
    try {
      services
        .engagementStats()
        .then((response: any) => {
          dispatch(slice.actions.updateEngagementStats(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorEngagementStats(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorEngagementStats(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSummary() {
  return () => {
    dispatch(slice.actions.startLoadingSummary());
    try {
      summaryServices
        .summary('INNOVATION_IDENTITY')
        .then((response: any) => {
          dispatch(slice.actions.updateSummary(response as TypeSummary));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorSummary(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorSummary(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setSummaryFailed() {
  return () => {
    dispatch(
      slice.actions.updateSummary({
        computingState: 'error',
        summary: '',
      } as TypeSummary)
    );
  };
}

// ----------------------------------------------------------------------
