// @mui
import { List, Box } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding>
          {group.items.map((list) => (
            <NavListRoot key={list.title + list.path} list={list} />
          ))}
        </List>
      ))}
    </Box>
  );
}
