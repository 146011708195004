// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// redux
import { useSelector } from 'redux/store';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import { navBottomConfig } from './NavConfig';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));
// ----------------------------------------------------------------------

export default function NavbarVertical() {
  const { navConfig } = useSelector((state) => state.global);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 4,
          pb: 1,
          flexShrink: 0,
          alignItems: 'center',
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-start">
          <Logo isNavItem={false} />
        </Stack>
      </Stack>

      <NavSectionVertical sx={{ mt: 2.5 }} navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <NavSectionVertical sx={{ mt: 1, mb: 3 }} navConfig={navBottomConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        position: 'absolute',
      }}
    >
      <Drawer
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
            borderRightStyle: 'none',
            bgcolor: 'common.black',
          },
        }}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
