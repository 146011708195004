import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          color: theme.palette.common.dark + '!important',
          fontWeight: theme.typography.fontWeightBold,
          '&.link-underline': {
            textDecoration: 'underline',
          },
        },
      },
    },
  };
}
