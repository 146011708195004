import { createSlice } from '@reduxjs/toolkit';
// services
import * as services from 'services/news-feed';

// @types
import { TypeMessageContain } from '_types/newsFeed';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  newsFeedCount: number;

  //NEW FEED DETAIL
  isLoadingNewDetail: boolean;
  errorNewDetail: Error | string | null;
  newDetail: TypeMessageContain | null;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  newsFeedCount: 0,

  //NEW FEED DETAIL
  isLoadingNewDetail: false,
  errorNewDetail: null,
  newDetail: null,
};

const slice = createSlice({
  name: 'newsFeed',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingNewDetail(state) {
      state.isLoadingNewDetail = true;
      state.errorNewDetail = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasErrorNewDetail(state, action) {
      state.isLoadingNewDetail = false;
      state.errorNewDetail = action.payload;
      state.newDetail = null;
    },

    // GET NEWS FEED COUNT
    newsFeedPulling(state, action) {
      state.isLoading = false;
      state.newsFeedCount = action.payload?.newsCount;
    },

    // UPDATE NEWS FEED COUNT
    updateNewsFeedPulling(state) {
      state.isLoadingNewDetail = false;
      if (state.newsFeedCount > 0) {
        state.newsFeedCount = state.newsFeedCount - 1;
      }
    },

    // NEW DETAIL
    updateNewDetail(state, action) {
      state.isLoadingNewDetail = false;
      state.newDetail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function newsFeedPulling() {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .newsFeedPulling()
        .then((response: any) => {
          dispatch(slice.actions.newsFeedPulling(response.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNewDetail(messageId: string) {
  return () => {
    dispatch(slice.actions.startLoadingNewDetail());
    try {
      services
        .newsFeedDetail(messageId)
        .then((response: any) => {
          dispatch(slice.actions.updateNewDetail(response.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorNewDetail(error?.message));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasErrorNewDetail(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateNewsFeedPulling() {
  return () => {
    dispatch(slice.actions.updateNewsFeedPulling());
  };
}
// ----------------------------------------------------------------------
