import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps, Typography } from '@mui/material';
import { PATH_PAGE } from 'routes/paths';
//
import { LightTooltipStyle } from 'components/nav-section/vertical/style';
// ----------------------------------------------------------------------

export interface Props extends BoxProps {
  disabledLink?: boolean;
  isNavItem?: boolean;
}

export default function Logo({ disabledLink = false, isNavItem, sx }: Props) {
  const logo = (
    <LightTooltipStyle
      title={
        <Typography variant="caption" component="div" sx={{ textTransform: 'initial' }}>
          <strong>Hub</strong>
        </Typography>
      }
      arrow
      placement="right"
      sx={{ inset: '-4px auto auto -4px !important' }}
    >
      <Box sx={isNavItem ? { paddingRight: '11pt' } : {}}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.7129 22.3478C24.1165 22.3478 22.5988 22.2528 21.3783 21.3832C20.905 21.0454 20.7437 20.4016 21.009 19.9183C21.2085 19.5573 21.5863 19.3314 21.9981 19.3314C22.2401 19.3314 22.4715 19.4074 22.6646 19.551C23.4075 20.0977 24.5771 20.1125 24.7087 20.1125C24.7554 20.1125 24.8891 20.1082 24.8891 20.1082C27.1157 20.1082 28.9241 18.3098 28.9241 16.0998C28.9241 14.3436 27.1964 12.4037 24.4073 12.334C24.2948 12.3319 24.1844 12.3298 24.0761 12.3298C22.2825 12.3298 20.7861 12.7034 18.914 13.6216C17.2286 14.447 15.4096 16.3742 15.019 17.7462C14.9999 17.818 14.9808 17.8855 14.9659 17.951C14.845 18.4512 14.4141 18.8037 13.9153 18.8143H13.8558C13.512 18.8143 13.1936 18.6623 12.9792 18.3942C12.7712 18.1325 12.6948 17.7948 12.7712 17.4697C12.7945 17.3684 12.8221 17.2628 12.854 17.151C13.5141 14.8227 15.902 12.6042 17.9206 11.6164C20.0984 10.5504 21.9408 10.0966 24.0867 10.0966C24.212 10.0966 24.3372 10.0966 24.4624 10.1008C26.1223 10.143 27.7843 10.7488 29.0218 11.762C30.4057 12.8976 31.1699 14.4385 31.1699 16.0998C31.1699 19.5278 28.3659 22.3288 24.9188 22.3415C24.8785 22.3415 24.8063 22.3457 24.7087 22.3457L24.7129 22.3478Z" fill="#F7A273" />
          <path d="M24.7488 26.5568C19.7925 26.5252 17.6912 23.2661 17.4683 22.8946C17.2773 22.5948 16.3412 20.9948 16.7721 19.1183C17.0777 17.7863 17.9713 16.676 19.4274 15.819C20.3126 15.2977 21.3187 15.0338 22.4182 15.0338C23.9741 15.0338 25.1733 15.5615 25.307 15.6206C25.5787 15.7452 25.7846 15.9668 25.8865 16.2433C25.9884 16.5198 25.9778 16.8238 25.8525 17.0919C25.67 17.4845 25.2731 17.7378 24.8379 17.7378C24.6766 17.7378 24.5196 17.704 24.371 17.6365C24.3625 17.6322 23.4965 17.2544 22.4224 17.2544C21.7241 17.2544 21.0979 17.4148 20.5652 17.7293C19.6631 18.2613 19.1197 18.8903 18.952 19.5974C18.6994 20.6634 19.3277 21.6639 19.3553 21.7062L19.3892 21.761C19.4529 21.8666 20.9472 24.313 24.7297 24.3384H24.787C28.6501 24.3384 30.7409 21.8645 30.8279 21.7589C31.0402 21.5014 31.3564 21.3536 31.6918 21.3536C31.9465 21.3536 32.197 21.4423 32.3944 21.6027C32.8699 21.9869 32.942 22.6856 32.5578 23.1584C32.4453 23.2977 29.7369 26.559 24.7828 26.559H24.7467L24.7488 26.5568Z" fill="#B76BAB" />
          <path d="M33.3328 21.0898C33.1778 21.0898 33.0271 21.0581 32.8849 20.9948C32.6111 20.8745 32.4031 20.657 32.2948 20.3805C32.1866 20.104 32.193 19.8022 32.3139 19.532C32.7745 18.4913 33.008 17.3789 33.008 16.2306C33.008 14.0438 32.1356 11.9836 30.5586 10.4279C28.9963 8.88915 26.9459 8.0406 24.7808 8.0406H24.7023C23.6474 8.04904 22.62 8.25379 21.6479 8.65063C21.512 8.70551 21.3698 8.73295 21.2255 8.73295C20.7713 8.73295 20.3658 8.46276 20.1918 8.04271C19.9583 7.47701 20.2321 6.82687 20.801 6.59468C22.0342 6.09441 23.3396 5.83267 24.6811 5.82211H24.7617C27.5275 5.82211 30.1446 6.89864 32.125 8.85327C34.1288 10.829 35.2325 13.4485 35.2346 16.2306C35.2346 17.6892 34.9396 19.1014 34.3538 20.4291C34.1755 20.8322 33.7743 21.0919 33.3307 21.0919L33.3328 21.0898Z" fill="#F7B3B4" />
          <path d="M22.3671 33.2609C22.0593 33.2609 21.7749 33.1405 21.5627 32.9189L15.6576 26.7996C15.2309 26.3584 15.2458 25.6534 15.6894 25.2313C15.8974 25.0307 16.1734 24.921 16.4621 24.921C16.7698 24.921 17.0543 25.0413 17.2665 25.2629L23.1716 31.3822C23.5982 31.8234 23.5834 32.5284 23.1397 32.9506C22.9317 33.1511 22.6558 33.2609 22.3671 33.2609Z" fill="#F7A273" />
          <path d="M24.9484 30.4323C24.6448 30.4323 24.3604 30.312 24.1481 30.0967L23.7448 29.6851C23.5368 29.4719 23.4243 29.1933 23.4286 28.8956C23.4328 28.6001 23.5517 28.3215 23.7661 28.1146C23.9762 27.912 24.2522 27.8001 24.5451 27.8001C24.838 27.8001 25.133 27.9204 25.3453 28.1357L25.7486 28.5473C25.9566 28.7605 26.0691 29.0392 26.0648 29.3368C26.0606 29.6323 25.9417 29.9109 25.7274 30.1178C25.5172 30.3204 25.2413 30.4323 24.9484 30.4323Z" fill="#B76BAB" />
          <path d="M19.6524 36.0028C19.3446 36.0028 19.0581 35.8804 18.8458 35.6608L8.82505 25.2376C8.61916 25.0222 8.50879 24.7415 8.51515 24.446C8.52152 24.1505 8.64463 23.874 8.85901 23.6692C9.06703 23.4708 9.34084 23.361 9.62952 23.361C9.93729 23.361 10.2238 23.4835 10.4361 23.7051L20.4569 34.1284C20.6628 34.3437 20.7731 34.6244 20.7668 34.9199C20.7604 35.2155 20.6373 35.492 20.4229 35.6967C20.2149 35.8951 19.9411 36.0049 19.6524 36.0049V36.0028Z" fill="#B76BAB" />
          <path d="M14.4543 25.1067C14.1466 25.1067 13.8621 24.9864 13.6499 24.7647L10.4426 21.4423C8.37735 19.3821 8.354 16.8576 8.354 16.7499C8.354 13.666 10.4384 10.9261 13.6626 9.76728C13.7857 9.72295 13.9131 9.70184 14.0426 9.70184C14.5117 9.70184 14.9319 9.99736 15.0911 10.4343C15.193 10.7129 15.1782 11.0148 15.0508 11.2829C14.9234 11.5509 14.7006 11.7536 14.4204 11.8549C12.0537 12.7056 10.5849 14.58 10.5849 16.7499C10.5849 16.8175 10.6273 18.4871 12.0303 19.8824L15.2609 23.2281C15.4668 23.4413 15.5772 23.7241 15.5708 24.0196C15.5645 24.3151 15.4435 24.5917 15.227 24.7985C15.019 24.9969 14.743 25.1067 14.4543 25.1067Z" fill="#F7B3B4" />
          <path d="M7.59841 23.5172C7.29063 23.5172 7.0062 23.3969 6.79394 23.1753L6.30362 22.6623C6.18051 22.5125 6.07014 22.371 5.96189 22.2254C5.78147 21.9826 5.71354 21.685 5.76448 21.3874C5.81755 21.0877 5.99372 20.8196 6.24631 20.6528C6.43098 20.5304 6.64536 20.4671 6.86611 20.4671C7.21846 20.4671 7.55171 20.6338 7.75973 20.9146C7.83614 21.018 7.9168 21.1214 7.99958 21.2249L8.37952 21.6175C8.79131 22.0439 8.81466 22.713 8.43259 23.1415C8.22033 23.38 7.91467 23.5172 7.59629 23.5172H7.59841Z" fill="#F7A273" />
          <path d="M5.46733 19.9077C4.97064 19.9077 4.52702 19.5742 4.38693 19.0972C3.98788 17.7293 3.89873 16.2581 4.11736 14.7214C4.4315 12.5156 5.43549 10.5061 7.01682 8.91029C8.60028 7.3145 10.6083 6.29075 12.82 5.9509C13.3655 5.86647 13.9216 5.82214 14.4693 5.82214C15.8107 5.82214 17.114 6.067 18.3409 6.55249C18.6189 6.66225 18.8354 6.87123 18.9543 7.14352C19.0732 7.41582 19.0774 7.71767 18.967 7.99208C18.7972 8.41847 18.3897 8.69287 17.9291 8.69287C17.789 8.69287 17.6489 8.66754 17.5173 8.61477C16.5537 8.23482 15.5284 8.04063 14.4714 8.04063C14.0363 8.04063 13.5948 8.0744 13.1617 8.14195C9.63823 8.68443 6.82791 11.5172 6.32698 15.0317C6.15505 16.2433 6.2251 17.4022 6.54136 18.4766C6.69844 19.0106 6.43099 19.5848 5.92369 19.8106C5.77935 19.874 5.62652 19.9077 5.46945 19.9077H5.46733Z" fill="#F4CB72" />
        </svg>
      </Box>
    </LightTooltipStyle>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={PATH_PAGE.hub}>{logo}</RouterLink>;
}
