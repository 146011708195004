import * as api from 'utils/axios';
import * as endpoints from 'services/questionnaire/endpoints';
import { SCAN_API } from 'config';

export const answerQuestion = async (params: any) => api.sendPost(endpoints.answerQuestion, params);
export const scanQuestion = (scanId: any, params?: any) =>
  api.sendPut(SCAN_API + endpoints.SCAN_QUESTION.replace('scanId', scanId), params, {
    retry: true,
  });
export const scanInit = (params?: any) => api.sendPut(SCAN_API + endpoints.SCAN_INIT, params);
export const scanComplete = (scanId: any) =>
  api.sendPost(SCAN_API + endpoints.SCAN_COMPLETE.replace('scanId', scanId), {
    scanId,
  });
export const scanIscomplete = (scanId: any) =>
  api.sendGet(SCAN_API + endpoints.SCAN_IS_COMPLETE.replace('scanId', scanId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
export const scanCurrent = () =>
  api.sendGet(SCAN_API + endpoints.SCAN_CURRENT, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
    params: { includeAnswers: true },
  });
export const scanDraft = (scanId: any, type?: string) =>
  api.sendGet(SCAN_API + endpoints.SCAN_DRAFT + scanId + `?questionType=${type}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
