import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import globalReducer from './slices/global';
import questionnaireReducer from './slices/questionnaire';
import scorecardReducer from './slices/scorecard';
import profileReducer from './slices/profile';
import userReducer from './slices/user';
import newsFeedReducer from './slices/newsFeed';
import scanReducer from './slices/scan';
import workBookReducer from './slices/workBook';
import onboardingReducer from './slices/onboarding';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const combinedReducer = combineReducers({
  global: globalReducer,
  questionnaire: questionnaireReducer,
  scorecard: scorecardReducer,
  profile: profileReducer,
  user: userReducer,
  newsFeed: newsFeedReducer,
  scan: scanReducer,
  workBook: workBookReducer,
  onboarding: onboardingReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'global/logout') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export { rootPersistConfig, rootReducer };
