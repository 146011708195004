import React, { createContext, useContext, useState } from 'react';
import { Box, Typography, Modal } from '@mui/material';

const ModalStyle = {
  position: 'absolute' as 'absolute',
  bottom: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

interface RetryContexttInterface {
  error: (newMessage: string) => void;
}

export const RetryContext = createContext({} as RetryContexttInterface);

type RetryProviderProps = {
  children: React.ReactNode;
};

const RetryProvider = ({ children }: RetryProviderProps) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setMessage('');
    setOpen(false);
  };
  const error = (newMessage: string) => {
    setMessage(newMessage);
    setOpen(true);
  };

  return (
    <RetryContext.Provider value={{ error }}>
      {children}
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-retry-description"
        disableEscapeKeyDown
        hideBackdrop={true}
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-retry-description" variant="h6" component="h3">
            {message}
          </Typography>
        </Box>
      </Modal>
    </RetryContext.Provider>
  );
};

export function useRetry() {
  return useContext(RetryContext);
}

export { RetryProvider };
