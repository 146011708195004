// @types
import { TypeElementsConstant } from '_types/elements';
import { RolesNamesType } from '_types/user';

// Questionnaire
export const FREE_TEXT = 'free_text';
export const FREE_TEXT_AREA = 'free_text_area';
export const BOXES_SINGLE_SELECTION = 'boxes_single_selection';
export const BOXES_MULTI_SELECTION = 'boxes_multi_selection';
export const BOXES_MULTI_SELECTION_FREE_TEXT = 'boxes_multi_selection_free_text';
export const BOXES_SORTABLE_SELECTION = 'boxes_sortable_selection';
export const RADIO_BUTTON_3 = 'radio_button_3';
export const RADIO_BUTTON_5 = 'radio_button_5';
export const RADIO_BUTTON_5_TEXT = 'radio_button_5_text';
export const DROPDOWN_SINGLE_SELECTION = 'dropdown_single_selection';
export const DROPDOWN_MULTI_SELECTION = 'dropdown_multi_selection';
export const INTRO = 'intro';
export const CUSTOM_LOCATION = 'custom_location';

// Roles
export const ROLES = {
  USER: 'ROLE_USER',
  ADMIN: 'ROLE_ADMIN',
};

export const ROLE_NAMES: RolesNamesType = {
  ROLE_USER: 'User',
  ROLE_ADMIN: 'Admin',
};

export const ELEMENT: TypeElementsConstant = {
  EMPATHY: {
    name: 'Empathy',
    key: 'empathy',
    image: `${process.env.PUBLIC_URL}/assets/questionnaire/empathy.svg`,
    color: '#FAB17D',
    colorGradientFirst: '#CC6699',
    colorGradientSecond: '#FF9966',
    backgroundColor: 'linear-gradient(89.93deg, #CC6699 0%, #FF9966 100%)',
  },
  FOCUS: {
    name: 'Focus',
    key: 'focus',
    image: `${process.env.PUBLIC_URL}/assets/questionnaire/focus2.svg`,
    color: '#F79769',
    colorGradientFirst: '#80AED0',
    colorGradientSecond: '#F79669',
    backgroundColor: 'linear-gradient(180deg, #80AED0 0%, #F79669 100%)',
  },
  MOTIVATION: {
    name: 'Motivation',
    key: 'motivation',
    image: `${process.env.PUBLIC_URL}/assets/questionnaire/motivation.svg`,
    color: '#B86CAC',
    colorGradientFirst: '#FACA9E',
    colorGradientSecond: '#B76DAC',
    backgroundColor: 'linear-gradient(180deg, #FACA9E 0%, #B76DAC 100%)',
  },
  SYNERGY: {
    name: 'Synergy',
    key: 'synergy',
    image: `${process.env.PUBLIC_URL}/assets/questionnaire/synergy.svg`,
    color: '#6AC9CA',
    colorGradientFirst: '#CC6699',
    colorGradientSecond: '#6AC9CA',
    backgroundColor: 'linear-gradient(0deg, #CC6699 0%, #6AC9CA 100%)',
  },
  MAGIC: {
    name: 'Magic',
    key: 'magic',
    image: `${process.env.PUBLIC_URL}/assets/questionnaire/magic.svg`,
    color: '#70C168',
    colorGradientFirst: '#71A771',
    colorGradientSecond: '#5BB7B7',
    backgroundColor: 'linear-gradient(0deg, #71A771 0%, #5BB7B7 100%)',
  },
};
