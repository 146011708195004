import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar as MUIAvatar, AvatarProps } from '@mui/material';

// ----------------------------------------------------------------------

type AvatarColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

// ----------------------------------------------------------------------

export interface Props extends AvatarProps {
  color?: AvatarColor;
}

const Avatar = forwardRef<HTMLDivElement, Props>(
  ({ color = 'default', children, sx, ...other }, ref) => {
    const theme = useTheme();

    if (color === 'default') {
      return (
        <MUIAvatar ref={ref} sx={sx} {...other}>
          {children}
        </MUIAvatar>
      );
    }

    const randomColor = (color: string) => {
      switch (color) {
        case 'primary':
          return 'linear-gradient(89.93deg, #CC6699 0%, #FF9966 100%)';
        case 'info':
          return 'linear-gradient(180deg, #80AED0 0%, #F79669 100%)';
        case 'success':
          return 'linear-gradient(180deg, #FACA9E 0%, #B76DAC 100%)';
        case 'warning':
          return 'linear-gradient(0deg, #CC6699 0%, #6AC9CA 100%)';
        case 'error':
          return 'linear-gradient(0deg, #3E627D 0%, #8FBADA 100%)';
        case 'default':
          return 'linear-gradient(180deg, #598383 0%, #66CCCC 100%)';
      }
    };

    return (
      <MUIAvatar
        ref={ref}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette[color].contrastText,
          background: randomColor(color),
          ...sx,
        }}
        {...other}
      >
        {children}
      </MUIAvatar>
    );
  }
);

export default Avatar;
