import * as api from 'utils/axios';
import * as endpoints from 'services/auth/endpoints';

export const loginService = (params?: any) => api.sendPost(endpoints.LOGIN, params);
export const registerService = (params?: any) => api.sendPost(endpoints.REGISTER, params);

export const forgetPassword = (params: any) => api.sendPost(endpoints.FORGET_PASSWORD, params);
export const resetPassword = (params: any) => api.sendPost(endpoints.RESET_PASSWORD, params);
export const checkResetToken = (params: any) => api.sendPost(endpoints.CHECK_RESET_TOKEN, params);
export const checkInviteToken = (params: any) => api.sendPost(endpoints.CHECK_INVITE_TOKEN, params);
