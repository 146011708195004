import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// config
import { NAVBAR } from '../../config';
//
import NavbarVertical from './navbar/NavbarVertical';
import Empy from 'components/Empy';

// ----------------------------------------------------------------------

const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
  width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  transition: theme.transitions.create('margin-left', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <NavbarVertical />
      <MainStyle>
        <Outlet />
        <Empy />
      </MainStyle>
    </Box>
  );
}
