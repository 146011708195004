import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
// config
import { HOST_API } from '../config';

/**
 * Retry Config Types
 */
export interface RetryConfig {
  /* Maximum times to retry */
  maxAttemps: number;

  /* HTTP status codes that should retry */
  httpCodes?: number[];

  /* Error codes that should retry */
  errorCodes?: string[];

  /* Delay time (ms) to retry */
  retryDelay: number;

  /* Current attempt retrying */
  currAttempt: number;

  /* Axios instance */
  instance?: AxiosInstance;
}

export interface RetryRequestConfig extends AxiosRequestConfig {
  retryConfig?: RetryConfig;
}

/**
 * Default retry configs
 */
export const retryConfig: RetryConfig = {
  maxAttemps: 3,
  httpCodes: [500],
  errorCodes: ['Network Error'],
  retryDelay: 2000,
  currAttempt: 0,
};

const retryInstance: AxiosInstance = axios.create({
  timeout: 60000,
  baseURL: HOST_API,
});

/**
 * @param  {Error}  error
 * @return {boolean}
 */
export function isNetworkError(error: AxiosError) {
  return !error.response && !Boolean(error.code) && error.code !== 'ECONNABORTED';
}

/**
 * Handle retry in the following cases:
 * - httpCode is 500
 * - error is a NetworkError
 * @param axiosInstance from Axios
 * @return void
 */
export function handleRetry(axiosInstance: AxiosInstance): void {
  axiosInstance.interceptors.request.use(
    //TODO: refactor common configs
    (config: RetryRequestConfig) => {
      if (config.url && config.url.charAt(0) === '/') {
        config.url = `${HOST_API}${config.url}`;
      }
      const { accessToken } = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCAL_TOKEN!) || '{}'
      );
      if (config.headers === undefined) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${accessToken}`;

      return {
        ...config,
        ...{
          retryConfig: {
            ...{
              ...retryConfig,
              instance: axiosInstance || null,
            },
            ...config.retryConfig,
          },
        },
      };
    },
    (error: AxiosError) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      let config = error.config as RetryRequestConfig;
      if (!config.retryConfig) {
        return Promise.reject(error);
      }
      const { instance: axiosInstance, maxAttemps, retryDelay, httpCodes } = config.retryConfig;
      let { currAttempt } = config.retryConfig;
      if (!isNetworkError(error) && httpCodes?.indexOf(error?.response?.status!) === -1) {
        return Promise.reject(error);
      }

      if (maxAttemps <= currAttempt || !axiosInstance) {
        return Promise.reject(error);
      }

      config = {
        ...config,
        ...{
          retryConfig: {
            maxAttemps,
            retryDelay,
            currAttempt: ++currAttempt,
            instance: axiosInstance,
          },
        },
      };

      return new Promise((resolve) =>
        setTimeout(() => {
          resolve(axiosInstance.request(config));
        }, retryDelay)
      );
    }
  );
}

export default retryInstance;
