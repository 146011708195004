import React, { memo } from 'react';

type IconProps = {
  color?: string;
};

function CornerIcon({ color = '#48525a' }: IconProps) {
  return (
    <svg width={45} height={50} fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M 0 0 L 31 0 C 1 1 1 48 31 50 L 0 50 L 0 0" fill="inherit" />
    </svg>
  );
}

export default memo(CornerIcon);
