import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

type IconProps = {
  element?: string;
  color?: string;
  width?: number;
  height?: number;
} & BoxProps;

function ElementIcon({ element, color = '', width = 30, height = 30, ...other }: IconProps) {
  const getElementIcon = () => {
    switch (element) {
      case 'empathy':
        return (
          <svg
            width={width || '26'}
            height={height || '20'}
            viewBox="0 0 26 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.1462 5.64665C24.9982 3.86082 23.9655 2.21004 22.3134 1.11853C21.2067 0.386083 19.9005 0 18.5363 0C16.0365 0 13.8086 1.27741 12.6648 3.24277C11.5211 1.27741 9.29475 0 6.79493 0C5.43081 0 4.1246 0.386083 3.01946 1.11853C1.36579 2.21004 0.333045 3.86082 0.185051 5.64824C0.0338393 7.4865 0.817244 9.29933 2.3921 10.7547C5.49033 13.6114 12.2337 19.8316 12.2337 19.8316C12.356 19.9444 12.5104 20 12.6664 20C12.8225 20 12.9769 19.9444 13.0992 19.8316C13.0992 19.8316 19.8442 13.6114 22.9424 10.7531C24.5173 9.29775 25.3007 7.48332 25.1478 5.64506L25.1462 5.64665ZM22.0753 9.84112C19.4484 12.2625 14.2011 17.1036 12.6648 18.5192C11.1286 17.102 5.88283 12.2625 3.25593 9.84112C1.96903 8.65269 1.32557 7.19892 1.44622 5.74992C1.56204 4.34541 2.3921 3.03782 3.72244 2.15761C4.62005 1.56499 5.68175 1.2504 6.79493 1.2504C9.30118 1.2504 11.4632 2.80744 12.0519 5.03495C12.1243 5.30982 12.3769 5.50207 12.6648 5.50207C12.9528 5.50207 13.2037 5.30982 13.2777 5.03495C13.8681 2.80585 16.0301 1.24881 18.538 1.24881C19.6511 1.24881 20.7128 1.56181 21.6105 2.15602C22.9408 3.03464 23.7692 4.34223 23.8867 5.74675C24.0073 7.19733 23.3639 8.6511 22.0786 9.83953L22.0753 9.84112Z"
              fill={`${color}` || '#FAB17D'}
            />
          </svg>
        );
      case 'focus':
        return (
          <svg
            width={width || '21'}
            height={height || '20'}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5777 15.5131L15.3427 11.2772C16.734 8.34635 16.2182 4.7295 13.7982 2.30898C12.3094 0.819854 10.3299 0 8.21943 0C6.10893 0 4.12945 0.819856 2.64066 2.30619C-0.434491 5.38204 -0.437279 10.3904 2.64066 13.4663C4.12945 14.9554 6.10892 15.7724 8.21664 15.7724C9.4099 15.7724 10.5641 15.5103 11.6096 15.0112L15.8418 19.2443C16.3297 19.7323 16.9793 20 17.6735 20C18.3677 20 19.0173 19.7323 19.5052 19.2443L19.5777 19.169C20.0656 18.681 20.3333 18.0312 20.3333 17.3369C20.3333 16.6425 20.0656 15.9927 19.5777 15.5047V15.5131ZM3.41294 12.6994C0.761562 10.0474 0.761562 5.73341 3.41294 3.08143C4.69542 1.79866 6.40166 1.09314 8.21943 1.09314C10.0372 1.09314 11.7462 1.79866 13.0287 3.08143C15.6801 5.73341 15.6773 10.0446 13.0287 12.6966C11.7462 13.9794 10.0372 14.6877 8.21943 14.6877C6.40166 14.6877 4.69542 13.9822 3.41573 12.6994H3.41294ZM18.8082 18.4049L18.733 18.4774C18.1475 19.063 17.1968 19.063 16.6113 18.4774L12.591 14.4562C13.0204 14.169 13.4246 13.8399 13.7954 13.4663C14.1718 13.0898 14.5008 12.6882 14.7824 12.2616L18.8027 16.2828C19.0842 16.5644 19.2404 16.9409 19.2404 17.3424C19.2404 17.744 19.0842 18.1205 18.8027 18.4021L18.8082 18.4049Z"
              fill={`${color}` || '#F79769'}
            />
          </svg>
        );
      case 'motivation':
        return (
          <svg
            width={width || '26'}
            height={height || '16'}
            viewBox="0 0 26 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.0787 1.31018C25.0787 1.31018 25.082 1.2841 25.0852 1.2678C25.1177 0.905961 24.8545 0.586501 24.4937 0.553904C20.3174 0.16599 18.3153 1.89368 14.6817 5.02959C13.8367 5.75978 12.8779 6.58776 11.7826 7.48094C9.32554 9.4857 5.45793 10.6136 0.891544 10.6527C0.530783 10.6527 0.241528 10.9526 0.244778 11.3144V14.8415C0.244778 15.2034 0.53729 15.4967 0.89805 15.4967C1.25881 15.4967 1.5513 15.2034 1.5513 14.8415V11.9501C2.45158 11.9208 3.32262 11.8523 4.16764 11.7447V14.8415C4.16764 15.2034 4.46014 15.4967 4.8209 15.4967C5.18166 15.4967 5.47417 15.2034 5.47417 14.8415V11.5394C6.4167 11.3633 7.31373 11.1352 8.15875 10.8548V14.8448C8.15875 15.2066 8.45126 15.5 8.81202 15.5C9.17278 15.5 9.46529 15.2066 9.46529 14.8448V10.3626C10.4143 9.95838 11.2788 9.47919 12.0459 8.93154V14.8448C12.0459 15.2066 12.3384 15.5 12.6991 15.5C13.0599 15.5 13.3524 15.2066 13.3524 14.8448V7.88841C14.1584 7.2169 14.8799 6.59428 15.5365 6.02708C15.6697 5.90973 15.803 5.7989 15.933 5.6848V14.8448C15.933 15.2066 16.2255 15.5 16.5862 15.5C16.947 15.5 17.2395 15.2066 17.2395 14.8448V4.583C17.2395 4.583 17.2395 4.57648 17.2395 4.57322C18.2048 3.77457 19.0563 3.13892 19.9176 2.68255V14.8448C19.9176 15.2066 20.2101 15.5 20.5709 15.5C20.9316 15.5 21.2241 15.2066 21.2241 14.8448V2.15446C21.2241 2.15446 21.2241 2.14142 21.2241 2.1349C21.9977 1.89368 22.8232 1.78937 23.782 1.82523V14.8448C23.782 15.2066 24.0745 15.5 24.4352 15.5C24.796 15.5 25.0885 15.2066 25.0885 14.8448L25.0787 1.31344V1.31018Z"
              fill={`${color}` || '#B86CAC'}
            />
          </svg>
        );
      case 'synergy':
        return (
          <svg
            width={width || '25'}
            height={height || '24'}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.881 1.78814C20.5918 -0.501225 16.6935 -0.529487 12.6651 1.34423C8.6386 -0.526487 4.74262 -0.498003 2.45432 1.78835C0.164694 4.07808 0.136787 7.97741 2.01132 12.0066C0.143545 16.0312 0.173151 19.9246 2.45825 22.2119C4.74668 24.5004 8.64295 24.5295 12.6697 22.6578C16.695 24.5269 20.5894 24.4978 22.8771 22.2121C25.1651 19.9239 25.1946 16.0283 23.3239 12.0021C25.1958 7.9744 25.1677 4.07704 22.881 1.78814ZM3.29588 2.62996C5.04287 0.882884 8.05012 0.785854 11.2844 2.05879C9.63506 2.99917 7.99218 4.2499 6.45646 5.78654C4.91828 7.32479 3.66667 8.97054 2.72622 10.6226C1.45179 7.38676 1.54821 4.37771 3.29588 2.62996ZM7.30115 6.62814C5.60254 8.32683 4.2687 10.1789 3.34808 12.0055C4.26845 13.8276 5.59982 15.6746 7.29408 17.3689C8.99152 19.0665 10.8421 20.3997 12.6675 21.3203C14.4929 20.3997 16.3437 19.0664 18.0412 17.3687C19.7354 15.6745 21.0668 13.8276 21.9871 12.0055C21.0665 10.179 19.7327 8.32697 18.0342 6.62836C16.3388 4.9329 14.4906 3.60084 12.6674 2.68036C10.8444 3.60083 8.99639 4.93282 7.30115 6.62814ZM3.30295 21.3671C1.55801 19.6221 1.45916 16.6197 2.72731 13.3897C3.66738 15.0377 4.91725 16.6792 6.45252 18.2137C7.98923 19.7504 9.63323 21.0012 11.2836 21.9414C8.05235 23.2111 5.04853 23.1128 3.30295 21.3671ZM22.0324 21.3673C20.2871 23.1127 17.2839 23.2112 14.0532 21.9422C15.7031 21.0017 17.3466 19.7506 18.8828 18.2135C20.4178 16.6784 21.6674 15.0363 22.6072 13.3877C23.8762 16.6186 23.7777 19.6219 22.0324 21.3673ZM18.8789 5.78675C20.4172 7.32436 21.6691 8.96937 22.6098 10.6207C23.8835 7.38552 23.7868 4.37719 22.0394 2.62974C20.292 0.882243 17.2837 0.785591 14.0486 2.0595C15.6987 2.99964 17.3424 4.25023 18.8789 5.78675ZM15.1725 11.945C15.1725 13.2029 14.1528 14.2227 12.895 14.2227C11.6371 14.2227 10.6174 13.2029 10.6174 11.945C10.6174 10.6871 11.6371 9.66738 12.895 9.66738C14.1528 9.66738 15.1725 10.6871 15.1725 11.945Z"
              fill={`${color}` || '#6AC9CA'}
            />
          </svg>
        );
      case 'magic':
        return (
          <svg
            width={width || '14'}
            height={height || '20'}
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9852 7.04998C12.6969 6.40642 12.084 6.00815 11.3814 6.00815H10.3759L11.6745 2.5548C11.8926 1.97438 11.8126 1.32354 11.4613 0.813552C11.11 0.303564 10.531 0 9.9132 0H3.25306C2.31787 0 1.5329 0.672698 1.38996 1.60039L0.207666 9.26964C0.122869 9.81606 0.280348 10.3673 0.638915 10.7875C0.997481 11.2076 1.51837 11.448 2.06834 11.448H3.82483L2.35906 17.5703L2.35422 17.5582L1.97384 19.2144C1.90601 19.5107 2.0538 19.8143 2.32999 19.9405C2.5868 20.0595 2.88964 19.9964 3.07862 19.7827L4.23185 18.4761L12.6994 8.94422C13.167 8.41724 13.2784 7.69354 12.9901 7.04998H12.9852ZM12.004 8.32981L3.12465 18.3256L4.77454 11.4359L5.79452 10.5228H2.06834C1.47961 10.5228 1.02897 9.99577 1.11861 9.41293L2.30092 1.74367C2.3736 1.27497 2.77578 0.927693 3.25064 0.927693H9.91077C10.5819 0.927693 11.047 1.60039 10.8096 2.22938L9.04101 6.93341H11.3814C12.1034 6.93341 12.4862 7.78825 12.0064 8.32738L12.004 8.32981Z"
              fill={`${color}` || '#70C168'}
            />
          </svg>
        );
    }
  };
  return <Box {...other}>{getElementIcon()}</Box>;
}

export default memo(ElementIcon);
