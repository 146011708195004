import { createSlice } from '@reduxjs/toolkit';
// @types
import { TypeQuestionnaire, TypeQuestionParams } from '_types/questionnaire';
// mock data
import _onboarding from '_mock/onboarding';
// redux
import { dispatch } from 'redux/store';
// services
import * as services from 'services/organisation';
// utils
import history from 'utils/history';
import { isNetworkError } from 'utils/retry';
// route
import { PATH_PAGE } from 'routes/paths';
import { IOrganisationProfile } from '_types/organisationProfile';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  failAnswer: string[];
  isStarted: boolean;
  progressActive: number;
  questionActive: number;
  questionSplideActive: number;
  progressQuestionComplete: boolean;
  onboardingComplete: boolean;
  onboarding: TypeQuestionnaire[];
  progressNumber: number;
  elementInfoStep: number;
  answerDetails: TypeQuestionParams[] | null;
  organisationProfile: IOrganisationProfile;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  failAnswer: [],
  isStarted: false,
  progressActive: 0,
  questionActive: 0,
  questionSplideActive: 0,
  progressQuestionComplete: false,
  onboardingComplete: false,
  onboarding: _onboarding.questions,
  progressNumber: 0,
  elementInfoStep: 0,
  answerDetails: null,
  organisationProfile: {} as IOrganisationProfile,
};

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      if (isNetworkError(action.payload)) {
        state.failAnswer.push(JSON.parse(action.payload.config.data));
      }
      state.isLoading = false;
      state.error = action.payload;
    },
    // ONBOARDING
    initOnboarding(state, action) {
      state.isStarted = true;
    },
    updateProgressActive(state, action) {
      state.progressActive = action.payload;
    },
    updateQuestionActive(state, action) {
      state.questionActive = action.payload;
    },
    updateQuestionSplideActive(state, action) {
      state.questionSplideActive = action.payload;
    },
    updateProgressQuestionComplete(state, action) {
      state.progressQuestionComplete = action.payload;
    },
    updateOnboardingComplete(state, action) {
      state.onboardingComplete = action.payload;
    },
    updateOnboarding(state, action) {
      state.isLoading = false;
      if (
        state.onboarding[state.progressActive].question?.length ===
        state.questionSplideActive + 1
      ) {
        if (state.progressActive === state.onboarding.length - 1) {
          state.progressQuestionComplete = true;
        } else {
          state.questionSplideActive = 0;
          state.questionActive = 0;
          state.progressActive = state.progressActive + 1;
        }
      } else {
        state.questionSplideActive = state.questionActive + 1;
      }
    },
    updateElementInfoStep(state, action) {
      state.elementInfoStep = state.elementInfoStep + action.payload;
    },
    updateAnswerDetails(state, action) {
      state.answerDetails = action.payload;
    },
    updateOrganisationProfile(state, action) {
      state.isLoading = false;
      state.organisationProfile = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function initOnboarding() {
  return () => {
    dispatch(slice.actions.initOnboarding(true));
  };
}

// ----------------------------------------------------------------------

export function updateQuestionActive(questionActive: number) {
  return () => {
    dispatch(slice.actions.updateQuestionActive(questionActive));
  };
}

// ----------------------------------------------------------------------

export function updateQuestionSplideActive(questionActive: number) {
  return () => {
    dispatch(slice.actions.updateQuestionSplideActive(questionActive));
  };
}

// ----------------------------------------------------------------------

export function updateProgressQuestionComplete(progressComplete: boolean) {
  return () => {
    dispatch(slice.actions.updateProgressQuestionComplete(progressComplete));
  };
}

// ----------------------------------------------------------------------

export function updateOnboardingComplete(isComplete: boolean) {
  return () => {
    dispatch(slice.actions.updateOnboardingComplete(isComplete));
  };
}

// ----------------------------------------------------------------------

export function updateElementInfoStep(elementInfoStep: number) {
  return () => {
    dispatch(slice.actions.updateElementInfoStep(elementInfoStep));
  };
}

// ----------------------------------------------------------------------

export function loadOrganisationProfile() {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .organisationProfile()
        .then((response: any) => {
          dispatch(slice.actions.updateOrganisationProfile(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateOrganisationProfile(data: IOrganisationProfile) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .updateOrganisationProfile(data)
        .then((response: any) => {
          dispatch(slice.actions.updateOrganisationProfile(response?.data));
          dispatch(slice.actions.updateOnboarding(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateOnboarding() {
  return () => {
    dispatch(slice.actions.updateOnboarding(null));
  };
}

// ----------------------------------------------------------------------

export function onboardingComplete() {
  return () => {
    dispatch(slice.actions.updateOnboardingComplete(true));
    history.push(PATH_PAGE.hub);
  };
}

// ----------------------------------------------------------------------
