import * as api from 'utils/axios';
import * as endpoints from 'services/news-feed/endpoints';
import { NEWS_FEED_API } from 'config';

export const newsFeedDetail = (messageId: string) =>
  api.sendGet(NEWS_FEED_API + endpoints.NEWS_FEED_DETAIL.replace('messageId', messageId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const newsFeedPulling = () =>
  api.sendGet(NEWS_FEED_API + endpoints.NEWS_FEED_PULLING, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
export const newsFeedList = (latestTime?: number) =>
  api.sendGet(
    NEWS_FEED_API +
      endpoints.NEWS_FEED_LIST +
      `?category=NEWS` +
      (latestTime ? `&latestTimeStamp=${latestTime}` : ''),
    {
      headers: { 'Content-Type': 'application/json', accept: 'application/json' },
      data: {},
    }
  );

export const newsFeedListPinned = () =>
  api.sendGet(NEWS_FEED_API + endpoints.NEWS_FEED_LIST_PINNED, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
