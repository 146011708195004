import jwtDecode from 'jwt-decode';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from 'utils/axios';
import { handleRefreshToken, isValidToken, setSession } from 'utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '_types/auth';
// services
import * as services from 'services/auth';
// redux
import { useDispatch } from 'redux/store';
import { getProfileInfo } from 'redux/slices/profile';
// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  ForgetPassword = 'FORGETPASSWORD',
  ResetPassword = 'RESETPASSWORD',
  CheckResetToken = 'CHECKRESETTOKEN',
  checkInviteToken = 'CHECK_INVITE_TOKEN',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: undefined;
  [Types.ForgetPassword]: undefined;
  [Types.ResetPassword]: undefined;
  [Types.CheckResetToken]: undefined;
  [Types.checkInviteToken]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: false,
      };
    case 'FORGETPASSWORD':
      return {
        ...state,
      };
    case 'RESETPASSWORD':
      return {
        ...state,
      };
    case 'CHECKRESETTOKEN':
      return {
        ...state,
      };
    case Types.checkInviteToken:
      return {
        ...state,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const dispatchStore = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const { accessToken, refreshToken } = JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LOCAL_TOKEN!) || '{}'
        );

        if (refreshToken && isValidToken(accessToken)) {
          let userData = await handleRefreshToken(refreshToken);
          const { roles } = jwtDecode<{ roles: string[] }>(userData?.token);
          userData = { ...userData, roles };
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: userData,
            },
          });
          // Get ProfileAvatar
          dispatchStore(getProfileInfo());
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [dispatchStore]);

  const login = async (email: string, password: string) => {
    const response = await services.loginService({
      username: email,
      password,
    });
    if (response) {
      let userData = response as AuthUser;
      if (userData) {
        setSession(userData);
        const { roles } = jwtDecode<{ roles: string[] }>(userData?.token);
        userData = { ...userData, roles };
      }
      dispatch({
        type: Types.Login,
        payload: {
          user: userData,
        },
      });
      // Get ProfileAvatar
      dispatchStore(getProfileInfo());
    }
  };

  const logout = async (email: string) => {
    await axios.post('/api/auth/v1/logout', {
      username: email,
    });
    setSession({});
    dispatch({ type: Types.Logout });
  };

  const register = async (
    firstName: string,
    lastName: string,
    organisation: string,
    email: string,
    password: string
  ) => {
    await services.registerService({
      username: email,
      firstName,
      lastName,
      organisationName: organisation,
      email,
      password,
    });
    dispatch({
      type: Types.Register,
    });
  };

  const forgetPassword = async (email: string) => {
    const params = { email, resetPasswordUrl: window.location.origin };
    await services.forgetPassword(params);
    dispatch({ type: Types.ForgetPassword });
  };

  const resetPassword = async (newPassword: string, resetToken: string) => {
    const params = { newPassword, resetToken };
    await services.resetPassword(params);
    dispatch({ type: Types.ResetPassword });
  };

  const checkResetToken = async (resetToken: string) => {
    const params = { resetToken };
    await services.checkResetToken(params);
    dispatch({ type: Types.CheckResetToken });
  };

  const updateUser = (value: AuthUser) => {
    const userData = { ...state.user, ...value };

    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: true,
        user: userData,
      },
    });
  };

  const checkInviteToken = async (inviteToken: string) => {
    const params = { inviteToken };
    const response = (await services.checkInviteToken(params)) as any;
    dispatch({
      type: Types.checkInviteToken,
    });
    return response;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        forgetPassword,
        resetPassword,
        checkResetToken,
        updateUser,
        checkInviteToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
