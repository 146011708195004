import React, { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

type IconProps = {
  color?: string;
  width?: number;
  height?: number;
} & BoxProps;

function MinusIcon({ color = '', width = 10, height = 5, ...other }: IconProps) {
  return (
    <Box {...other}>
      <svg width={width} height={height} viewBox="0 0 6 3" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.32173 0.978693V2.65909H0.678267V0.978693H5.32173Z" fill="inherit" />
      </svg>
    </Box>
  );
}

export default memo(MinusIcon);
