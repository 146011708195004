export const SCORE_ICS = '/api/v1/score/innovation-culture-score';
export const SCORE_INNOVATION_PERCEPTIONS = '/api/v1/score/innovation-perceptions';
export const SCORE_ELEMENT_THEMES = '/api/v1/score/element-themes';
export const SCORE_IDENTITY = '/api/v1/score/identity';
export const SCORE_DEPTH_ELEMENTS = '/api/v1/score/depth-elements/';
export const SCORE_DEPTH_BEHAVIOURS = '/api/v1/score/depth-behaviours/';
export const SCORE_IDENTITY_PERCEPTIONS = '/api/v1/score/identity-perceptions/';
export const SCORE_IDENTITY_PERCEPTIONS_JOURNEY = '/api/v1/score/identity-journey/';
export const SCORE_IDENTITY_PERCEPTIONS_MINDSET = '/api/v1/score/identity-mindset';
export const SCORE_DEPTH_IDENTITY = '/api/v1/score/depth-identity/';
