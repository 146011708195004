import { Navigate } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// routes
import { PATH_PAGE } from 'routes/paths';
// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  roles?: string;
  children: React.ReactNode;
};

export default function RoleBasedGuard({ roles, children }: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user } = useAuth();

  const currentRole = user?.roles; // admin;

  if (typeof roles !== 'undefined' && !currentRole.includes(roles)) {
    return <Navigate to={PATH_PAGE.page403} />;
  }

  return <>{children}</>;
}
