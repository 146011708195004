import * as api from 'utils/axios';
import * as endpoints from 'services/scorecard/endpoints';
import { SCORECARD_API } from 'config';

export const innovationCultureScores = () =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_ICS, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const scoreInnovationPerceptions = () =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_INNOVATION_PERCEPTIONS, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const scoreElementThemes = () =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_ELEMENT_THEMES, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const scoreIdentity = () =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_IDENTITY, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const scoreInDepthElements = (type: string) =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_DEPTH_ELEMENTS + type?.toLowerCase(), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const scoreInDepthBehaviours = () =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_DEPTH_BEHAVIOURS, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const scoreInDepthBehavioursByName = (elementName: string) =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_DEPTH_BEHAVIOURS + `${elementName}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const scoreDepthIdentity = (type: string) =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_DEPTH_IDENTITY + type, {
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    data: {},
  });

export const scoreIdentityPerceptionsJourney = (type: string) =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_IDENTITY_PERCEPTIONS_JOURNEY + type?.toLowerCase(), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const scoreIndentityMindset = () =>
  api.sendGet(SCORECARD_API + endpoints.SCORE_IDENTITY_PERCEPTIONS_MINDSET, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
