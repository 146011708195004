import * as api from 'utils/axios';
import * as endpoints from 'services/empy/endpoints';
import { SCAN_API } from 'config';

export const sendFeedback = (params: any) =>
  api.sendPost(endpoints.EMPY_FEEDBACK, params, {
    headers: { 'Content-Type': 'application/json' },
    data: {},
  });
export const sendContactUs = (params: any) =>
  api.sendPost(endpoints.EMPY_CONTACT_US, params, {
    headers: { 'Content-Type': 'application/json' },
  });
export const sendQuestion = (params: any) =>
  api.sendPost(SCAN_API + endpoints.EMPY_CHAT, params, {
    headers: { 'Content-Type': 'application/json' },
  });
