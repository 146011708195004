import * as api from 'utils/axios';
import * as endpoints from 'services/organisation/endpoints';

export const organisationProfile = () =>
  api.sendGet(endpoints.ORGANISATION_PROFILE, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
export const updateOrganisationProfile = (params: any) =>
  api.sendPatch(endpoints.UPDATE_ORGANISATION_PROFILE, params, {
    headers: { 'Content-Type': 'application/json' },
  });
